.containter {
  font-size: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 100%;
  width: 90%;
  margin: auto;
  padding-top: 9rem;
  h3 {
    font-size: 36px;
  }
}

// Services.module.scss

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.servicesHeading {
  font-size: 4rem; /* Larger font size using rem */
  margin-bottom: 2rem;
  text-align: left; /* Align left */
  padding-bottom: 3rem;
  margin-top: 0;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
}

.card {
  width: calc(33% - 2rem);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  overflow: hidden;
}

.cardImage {
  width: 100%;
  height: 40%;
  border-radius: 5px;
  object-fit: cover;
}

.cardContent {
  padding: 2rem;
  h2,
  p {
    margin: 0;
  }
  p {
    color: #777;
  }
}

.cardHeader,
.cardDescription {
  padding: 0 0 10px 0;
}

.cardContent {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.cardContent.visible {
  opacity: 1;
}

.heroSection {
  background-image: url("../../assets/works/services/hero.jpg");
  background-size: cover;
  background-position: center;
  height: 70vh; /* Set height to 70% of the viewport height */
  width: 100%; /* Cover entire width */
  display: flex;
  padding: 50px; /* Adjust padding as needed */
  margin: 5rem 0;
  border-radius: 1rem;
}

.quoteContainer {
  max-width: 800px; /* Set a maximum width */
  text-align: left; /* Align text to the left */
}

.quote {
  margin-bottom: 10px; /* Add space between quote and author */
  color: white; /* Change font color to white */
}

.author {
  padding-top: 3rem;
  color: white; /* Change font color to white */
}
@media (max-width: 900px) {
  .card {
    width: 45%;
  }
}
@media (max-width: 700px) {
  .card {
    width: 100%;
  }
}
