.homeContainer {
  height: 100%;
  .carouselContainer {
    height: 100%;
    .carouselSlickList {
      height: 100%;
      :global(.slick-list),
      :global(.slick-track),
      :global(.slick-slide),
      :global(.slick-slide) > div {
        height: 100%;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .link {
    width: 100%;
    height: 100% !important;
    display: block !important;
  }
}
