.aboutContainer {
  --eca-font-size: 25rem;
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
  font-size: 2rem;
  min-height: 100%;
  width: 80%;
  margin: auto;
  padding-top: 9rem;
  .contentContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: auto;
    padding: 1rem 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Customize the box-shadow values */
    transition: box-shadow 0.3s ease-in-out;
    &:hover {
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Customize the box-shadow values for hover effect */
    }
    .leftSection,
    .rightSection {
      width: 50%;
    }
    .leftSection {
      font-family: Roboto, sans-serif;
      color: #e2e8f0;
      font-size: var(--eca-font-size);
      display: flex;
      align-items: center;
    }
    .rightSection {
      text-align: center;
      h2 {
        margin: 0;
        margin-top: 0.5rem;
      }
    }
  }

  .heading1 {
    width: 100%;
    text-align: center;
    font-size: 4rem;
    margin-top: 0;
  }

  .teamSection {
    margin-bottom: 2rem;
    h3 {
      text-align: center;
    }
    .imagesList {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .imageContainer {
        width: 40%;
        height: 50rem;
        img {
          width: 100%;
          height: 80%;
          object-fit: cover;
        }
        > section {
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .aboutContainer {
    --eca-font-size: 19rem;
  }
}
@media (max-width: 1200px) {
  .aboutContainer {
    --eca-font-size: 15rem;
  }
}
@media (max-width: 900px) {
  .aboutContainer {
    --eca-font-size: 12rem;
  }
}

@media (max-width: 768px) {
  .teamSection {
    .imagesList {
      justify-content: center !important;
      .imageContainer {
        width: 100% !important;
        .image {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }
  }
  .aboutContainer {
    .content,
    .image {
      width: 100%;
    }
    .content {
      order: 1;
      padding-bottom: 1rem;
    }
  }
  .aboutContainer .contentContainer {
    .leftSection,
    .rightSection {
      width: 100%;
      justify-content: center;
    }
  }
}
