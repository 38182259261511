.wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1002;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  .gifContainer {
    img {
      width: 25rem;
      object-fit: contain;
      margin-bottom: -1.75rem;
    }
    .title1,
    .title2 {
      margin: 0;
      position: relative;
      width: 100%;
      text-align: center;
    }
    .title1 {
      font-size: 3rem;
      font-weight: 600;
      line-height: 1em;
      text-align: center;
      animation: slideInLeft 1s ease-in-out;
    }
    .title2 {
      display: inline-block;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
      line-height: 1em;
      width: 100%;
      padding-left: 0.3rem;
      letter-spacing: 0.5rem;
      animation: slideInRight 1s ease-in-out;
    }
  }
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
