// ContactPage.module.scss

.contactPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 9rem;
  font-size: 2rem;
}

.contactInfo {
  text-align: center;
  // margin-bottom: 2rem;
  width: 100%;
  max-width: 44rem; /* Adjust max width as needed */
  padding: 0 2rem;
  h1 {
    margin-top: 0;
  }
}

.mapContainer {
  width: 100%;
  max-width: 800px; /* Adjust max width as needed */
  height: 400px; /* Adjust height as needed */
  margin: auto; /* Add margin between contact info and map */
}

.communication {
  display: flex;
  justify-content: center;
  // align-items: center;
  gap: 1rem;
  padding: 0.5rem 0;
  &:not(:first-of-type) > span {
    transform: rotateX(-5deg);
  }
  > a {
    color: inherit;
    padding-bottom: 0.5rem;
    text-decoration: none;
  }
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 768px) {
  .mapContainer {
    width: 100%;
    max-width: none;
    height: 300px; /* Adjust height for mobile */
  }
}
