.headerContainer {
  position: fixed;
  top: 0;
  z-index: 1001;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2.5rem;
  color: #fff;
  &:not(.homePageHeader) {
    color: unset;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
  }
  .title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    color: inherit;
    img {
      width: 4rem;
    }
    h3 {
      font-size: 2.5rem;
      font-weight: 700;
      margin: 0;
      line-height: 1em;
    }
    span {
      display: inline-block;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
      line-height: 1em;
      width: 100%;
      letter-spacing: 0.3rem;
    }
  }
  .rightContainer {
    font-size: 3rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.drawerContainer {
  height: 100%;
  :global(.ant-drawer-content-wrapper) {
    height: 100% !important;
  }

  .appMenuContent {
    height: 100%;
    position: relative;
    nav {
      font-size: 2rem;
      font-weight: 400;
      height: calc(100% - 2.4rem);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 0.5rem;
      .link {
        color: #888888;
        &.active {
          color: #a0943f;
        }
      }
    }
    .rightText {
      position: absolute;
      top: 50%;
      right: -16.5rem;
      transform: rotate(90deg);
      margin: 0;
    }
    footer {
      font-size: 2.4rem;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      > span {
        cursor: pointer;
      }
    }
  }
}

.socialLink {
  color: inherit;
  &:hover {
    color: #1677ff;
  }
}
